<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">

        <div class="d-flex">
          <feather-icon icon="InboxIcon" size="19"/>
          <h4 class="mb-0 ml-50">Form Bilgileri</h4>
        </div>

        <b-link target="_blank" :to="{ name: 'form-request-edit', params: { id: dataInfo.parentFormRequestId } }" v-if="dataInfo.parentFormRequestId" class="d-flex justify-content-start align-items-center mt-1">
          <span> {{ dataInfo.parentFormFicheNo }} nolu form üzerinden revize edilmiştir.</span>
        </b-link>

        <b-row class="mt-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Form Adı" rules="required">
              <b-form-group label="Form Adı" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.formId" :options="formNameOptions" :reduce="val => val.value" :clearable="false" v-on:input="getFormInfo"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Müşteri" rules="required">
              <b-form-group label="Müşteri" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.customerId" :options="customerOptions" :reduce="val => val.value" :clearable="true" v-on:input="getCustomerContacts"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="İlgili Kişi">
              <b-form-group label="İlgili Kişi" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.customerContactId" :options="customerContactOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Veriliş Tarihi" rules="required">
              <b-form-group label="Veriliş Tarihi" :state="getValidationState(validationContext)">
                <flat-pickr v-model="dataInfo.docDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Amaç">
              <b-form-group label="Amaç">
                <b-form-input trim placeholder="Amaç" v-model="dataInfo.purpose" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Teslim Tarihi" rules="required">
              <b-form-group label="Teslim Tarihi" :state="getValidationState(validationContext)">
                <flat-pickr v-model="dataInfo.dueDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex" v-if="dataInfo.questionGroup.length > 0">
          <feather-icon icon="InboxIcon" size="19"/>
          <h4 class="mb-0 ml-50">Talep Bilgileri</h4>
        </div>

        <b-card no-body class="border mt-1 p-1" v-if="dataInfo.questionGroup.length > 0">
          <div v-for="(group, index) in dataInfo.questionGroup" :key="group.formQuestionGroupId" class="">

            <span class="lead collapse-title">
                <b-avatar size="32" :text="(index+1).toString()" :variant="`light-primary`"/>
                {{ group.questionGroupName }}
            </span>

            <b-table
                class="mb-1 mt-1 ml-5 pr-5"
                :items="group.questions"
                :fields="questionFields"
                striped responsive bordered hover>

              <template #cell(questionName)="data">
                <div class="text-nowrap">
                  {{ data.item.required ? data.value + ' *' : data.value }}
                </div>
              </template>

              <template #cell(answer)="data">
                <b-form-input trim v-model="data.item.answer" v-if="data.item.questionTypeId === 'TEXT'"/>
                <b-form-input trim v-model="data.item.answer" v-if="data.item.questionTypeId === 'NUMBER'"/>
                <flat-pickr :config="dateConfig" v-model="data.item.answer" class="form-control" v-if="data.item.questionTypeId === 'DATE'"/>
                <b-form-checkbox v-model="data.item.answer" switch inline v-if="data.item.questionTypeId === 'CHECKBOX'" value="true" unchecked-value="false">

                </b-form-checkbox>
                <b-form-select v-model="data.item.answer" :options="data.item.answerOptions" v-if="data.item.questionTypeId === 'DROPDOWN'"/>
              </template>

              <template #cell(description)="data">
                <div class="text-nowrap">
                  <b-form-input trim v-model="data.item.description"/>
                </div>
              </template>

            </b-table>
          </div>
        </b-card>

        <action-buttons :back-route="'form-request-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormTextarea, BTable, BAvatar, BFormCheckbox, BFormSelect, BLink
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/form_request/store"
import definitionModule from "@/views/system/definition/store"
import customerModule from "@/views/definition/customer/store"
import formModule from "@/views/definition/form/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";
import flatPickr from 'vue-flatpickr-component'
import {Turkish} from 'flatpickr/dist/l10n/tr';

export default {
  components: {
    BLink,
    BFormSelect,
    BFormCheckbox,
    BAvatar,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    flatPickr,

    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_CUSTOMER_MODULE_NAME = 'store-customer'
    const STORE_FORM_MODULE_NAME = 'store-form'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_CUSTOMER_MODULE_NAME, customerModule)
      store.registerModule(STORE_FORM_MODULE_NAME, formModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_CUSTOMER_MODULE_NAME)
        store.unregisterModule(STORE_FORM_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: null,
      formId: null,
      customerId: null,
      customerContactId: null,
      docDate: null,
      dueDate: null,
      purpose: '',
      description: '',
      questionGroup: []
    })

    const customerOptions = ref([])
    const formNameOptions = ref([])
    const customerContactOptions = ref([])

    busy.value = true
    store.dispatch('store-form/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          formNameOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-customer/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          customerOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
        if (dataInfo.value.customerId > 0) {
          getCustomerContacts(dataInfo.value.customerId)
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'form-request-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      busy.value = true
      let targetRoute = router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'
      if (router.currentRoute.name === 'form-request-revision') {
        targetRoute = 'addItem'
        dataInfo.value.parentFormRequestId = dataInfo.value.id
      }
      store.dispatch('store/' + targetRoute, dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'form-request-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const getCustomerContacts = (val) => {
      customerContactOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-customer/fetchContactList', {customerId: val}).then(response => {
          response.data.data.forEach((value, index) => {
            customerContactOptions.value.push({label: value.name + ' ' + value.lastName, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.customerContactId = null
      }
    }

    const questionFields = [
      {key: 'questionName', label: 'Soru', thStyle: {width: "20%"}},
      {key: 'answer', label: 'Cevap'},
      {key: 'description', label: 'Açıklama', thStyle: {width: "30%"}},
    ];

    const dateConfig = ref({
      dateFormat: 'd.m.Y',
      locale: Turkish,
    });

    const getFormInfo = (val) => {
      dataInfo.value.questionGroup = [];
      if (val != null) {
        busy.value = true
        store.dispatch('store-form/fetchItem', {id: val}).then(response => {
          response.data.data.questionGroups.forEach((group, index) => {
            const questions = [];
            group.questions.forEach((question, index2) => {
              questions.push({
                id: null,
                description: '',
                answer: '',
                formQuestionId: question.id,
                answerOptions: question.answerOptions,
                questionName: question.name,
                questionTypeId: question.questionTypeId,
                questionTypeName: question.questionTypeName,
                required: question.required
              })
            });
            dataInfo.value.questionGroup.push({
              id: null,
              formQuestionGroupId: group.id,
              questionGroupId: group.questionGroupId,
              questionGroupName: group.questionGroupName,
              questions: questions
            })
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,
      customerOptions,
      formNameOptions,
      customerContactOptions,
      questionFields,
      dateConfig,

      onSubmit,
      getValidationState,
      resetForm,
      getCustomerContacts,
      getFormInfo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
